<template>
    <v-container class="page-container" fill-height fluid grid-list-xl
    >
        <v-layout wrap id="configuration">
            <!-- Header title -->
            <h1 class="page-title text-xs-left mb-3 w-100">{{pageTitle}}</h1>

            <!-- Add requirement button -->
            <div class="flex-display flex-row flex-justify-end mb-3 w-100">
                <button class="btn add-new" @click="showAddRequirementDialog('add',null)">
                  <em class="v-icon mdi mdi-plus"></em> {{addButtonText}}
                </button>
            </div>

            <!-- Requirements list -->
            <template v-if="isReady">
                <div class="item-container"
                     v-for="(requirement, index) in requirements"
                     :key="requirement.id"
                >
                    <p class="item-text w-100">{{requirement.title}}</p>
                    <div class="item-actions">
                        <button class="btn-edit" @click="showAddRequirementDialog('edit', index)">Edit</button>
                        <button class="btn-delete ml-2" @click="showDeleteConfirmation(requirement)">Delete</button>
                    </div>
                </div>
            </template>

            <!-- Add requirement dialog -->
            <add-skill-requirement v-if="addDialog"
                            :visible="addDialog"
                            :actionType="actionType"
                            :value="selectedRequirement"
                            v-on:submitRequirement="submitRequirement"
                            v-on:hide="hideAddRequirementDialog"
            >
            </add-skill-requirement>

          <!-- delete dialog -->
          <confirmation v-if="deleteDialog" :visible="deleteDialog">
            <template slot="title">
              Are you sure?
            </template>
            <template slot="desc">
              This requirement will be deleted. This action cannot be undone.
            </template>
            <template slot="actions">
              <a class="cancel-model" @click="hideDeleteConfirmation">Cancel</a>
              <button class="btn modal-action-button confirmation-delete" @click="deleteSkill">Delete</button>
            </template>
          </confirmation>
        </v-layout>

    </v-container>
</template>

<script>
    import AddSkillRequirement from "@/components/configuration/AddSkillRequirement";
    import Confirmation from "@/components/common/Confirmation";
    export default {
        'name': 'SelfCareRequirements',
        components: {
            AddSkillRequirement,
            Confirmation
        },
        data: () => ({
            pageTitle: 'Self Care Skill Requirements',
            addButtonText: 'New Requirement',
            isReady: true,
            addDialog: false,
            deleteDialog: false,
            actionType: '',
            requirements: [],
            skills: [],
            selectedIndex: null,
            selectedRequirement: {
                name: '',
                status: '',
                keywords: []
            }
        }),
        created() {
            this.getRequirements();
        },
        methods: {
            getRequirements() {
                let loader = this.$loading.show();
                this.$http.get(this.url + 'self_care/skill/requirements').then(response => {
                    loader.hide();
                    this.requirements = response.data.requirements;
                }).catch(error => {
                    loader.hide();
                    this.$toastr('error', error.response.data.message, 'Error');
                })
            },
            submitRequirement(requirement) {
                let loader = this.$loading.show();
                this.$http.put(this.url + 'self_care/skill_requirement', requirement).then(response => {
                    this.selectedIndex === null ?
                        this.requirements.push(response.data.requirement) :
                        this.requirements[this.selectedIndex] = response.data.requirement;
                  this.selectedIndex === null ?
                      this.skills.push(response.data.skill) :
                      this.skills[this.selectedIndex] = response.data.skill;
                    this.hideAddRequirementDialog();
                    this.$toastr('success', response.data.message, '');
                    console.log("Inside success");
                    loader.hide();
                }).catch(error => {
                    console.log("Inside error: " + error);
                    this.$toastr('error', error.response.data.message, '');
                    loader.hide();
                });
            },
            deleteSkill() {
                this.deleteDialog = false;
                let loader = this.$loading.show();
                this.$http.delete(this.url+'self_care/skill_requirement', {
                    data: {
                        id: this.selectedRequirement.id
                    }
                }).then((response) => {
                    loader.hide();
                    this.$toastr('success', response.data.message);
                    this.getRequirements();
                }).catch((error) => {
                    loader.hide();
                    this.$toastr('error',error.response.data.message);
                })
            },
            showAddRequirementDialog(action, index) {
                this.addDialog = true;
                this.actionType = action;
                this.selectedIndex = index;
                if (this.selectedIndex === null) {
                    this.resetSelectedRequirement();
                } else {
                    this.selectedRequirement = this.requirements[index];
                }
            },
            hideAddRequirementDialog() {
                this.resetSelectedRequirement();
                this.addDialog = false;
            },
            showDeleteConfirmation(requirement) {
              this.selectedRequirement = requirement;
              this.deleteDialog = true;
            },
            hideDeleteConfirmation() {
              this.deleteDialog = false;
              this.selectedRequirement = null;
            },
            resetSelectedRequirement() {
                this.selectedIndex = null;
                this.selectedRequirement = {
                    title: '',
                    detail: '',
                    image: ''
                }
            }
        }
    }
</script>
